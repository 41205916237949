import { AgencyDTO } from 'dtos/agent';
import { AgentDTO } from 'dtos/agent';
import { ApplicationBreachDataDTO, ApplicationDTO } from 'dtos/application';
import {
  DeepScanGwsActivityLogin,
  DeepScanGwsUser,
  DeepScanM365AuditLogSignIn,
  DeepScanM365User,
  NmapVulnerabilityData,
} from 'dtos/deep-scan';
import { SecurityFindingDTO } from 'dtos/security-finding';

export enum AlertCategory {
  INTERNAL_VULNERABILITIES = 'internal_vulnerabilities',
  TYPOSQUATTING = 'typosquatting',

  DARK_WEB_BREACH = 'dark_web_breach',
  DARK_WEB_ACCOUNT = 'dark_web_account',

  M365_FAILED_LOGINS = 'm365_failed_logins',
  M365_AUTH_REVOKED = 'm365_auth_revoked',
  M365_CONDITIONAL_ACCESS_VIOLATION = 'm365_conditional_access_violation',
  M365_MFA_FAILED = 'm365_mfa_failed',
  M365_SUCCESS_LOGINS = 'm365_success_logins',
  M365_MFA_DISABLED = 'm365_mfa_disabled',
  M365_MFA_ENABLED = 'm365_mfa_enabled',
  M365_PASSWORD_RESET = 'm365_password_reset',
  M365_USER_CREATE = 'm365_user_create',
  M365_USER_DELETE = 'm365_user_delete',
  M365_LOGIN_TOKEN = 'm365_login_token',
  M365_UNAPPROVED_GEO_LOGIN = 'm365_unapproved_geo_login',
  M365_NO_MFA = 'm365_no_mfa',
  M365_DEVICE = 'm365_device',
  M365_ADMIN_ROLE_ASSIGNMENT = 'm365_admin_role_assignment',
  M365_GROUP_MEMBERSHIP_CHANGE = 'm365_group_membership_change',
  M365_ADMIN_POLICY_CHANGE = 'm365_admin_policy_change',

  GWS_FAILED_LOGINS = 'gws_failed_logins',
  GWS_AUTH_REVOKED = 'gws_auth_revoked',
  GWS_SUCCESS_LOGINS = 'gws_success_logins',
  GWS_MFA_FAILED = 'gws_mfa_failed',
  GWS_MFA_DISABLED = 'gws_mfa_disabled',
  GWS_MFA_ENABLED = 'gws_mfa_enabled',
  GWS_PASSWORD_RESET = 'gws_password_reset',
  GWS_USER_CREATE = 'gws_user_create',
  GWS_USER_DELETE = 'gws_user_delete',
  GWS_LOGIN_TOKEN = 'gws_login_token',
  GWS_UNAPPROVED_GEO_LOGIN = 'gws_unapproved_geo_login',
  GWS_NO_MFA = 'gws_no_mfa',
  GWS_DEVICE = 'gws_device',
  GWS_ADMIN_ROLE_ASSIGNMENT = 'gws_admin_role_assignment',
  GWS_GROUP_MEMBERSHIP_CHANGE = 'gws_group_membership_change',
  GWS_ADMIN_POLICY_CHANGE = 'gws_admin_policy_change',
}

export const ALERT_CATEGORIES: { [key in AlertCategory]: string } = {
  [AlertCategory.INTERNAL_VULNERABILITIES]: 'Internal Vulnerabilities',
  [AlertCategory.TYPOSQUATTING]: 'TypoSquatting Domains',

  [AlertCategory.DARK_WEB_BREACH]: 'Dark Web Breach',
  [AlertCategory.DARK_WEB_ACCOUNT]: 'Dark Web Account',

  [AlertCategory.M365_FAILED_LOGINS]: 'Microsoft 365 Failed Logins',
  [AlertCategory.M365_AUTH_REVOKED]: 'Microsoft 365 Authentication Token Revoked',
  [AlertCategory.M365_CONDITIONAL_ACCESS_VIOLATION]: 'Microsoft 365 Conditional Access Violation',
  [AlertCategory.M365_MFA_FAILED]: 'Microsoft 365 MFA Failed',
  [AlertCategory.M365_SUCCESS_LOGINS]: 'Microsoft 365 Successful Logins',
  [AlertCategory.M365_MFA_DISABLED]: 'Microsoft 365 MFA Disabled',
  [AlertCategory.M365_MFA_ENABLED]: 'Microsoft 365 MFA Enabled',
  [AlertCategory.M365_PASSWORD_RESET]: 'Microsoft 365 Password Reset',
  [AlertCategory.M365_USER_CREATE]: 'Microsoft 365 User Create',
  [AlertCategory.M365_USER_DELETE]: 'Microsoft 365 User Delete',
  [AlertCategory.M365_LOGIN_TOKEN]: 'Microsoft 365 Login Using Token',
  [AlertCategory.M365_UNAPPROVED_GEO_LOGIN]: 'Microsoft 365 Login From Unapproved Location',
  [AlertCategory.M365_NO_MFA]: 'Microsoft 365 No MFA User',
  [AlertCategory.M365_DEVICE]: 'Microsoft 365 Registered Device',
  [AlertCategory.M365_ADMIN_ROLE_ASSIGNMENT]: 'Microsoft 365 Admin Role Assignment',
  [AlertCategory.M365_GROUP_MEMBERSHIP_CHANGE]: 'Microsoft 365 Group Membership Change',
  [AlertCategory.M365_ADMIN_POLICY_CHANGE]: 'Microsoft 365 Admin Policy Change',

  [AlertCategory.GWS_FAILED_LOGINS]: 'Google Workspace Failed Logins',
  [AlertCategory.GWS_AUTH_REVOKED]: 'Google Workspace Authentication Token Revoked',
  [AlertCategory.GWS_SUCCESS_LOGINS]: 'Google Workspace Successful Logins',
  [AlertCategory.GWS_MFA_FAILED]: 'Google Workspace MFA Failed',
  [AlertCategory.GWS_MFA_DISABLED]: 'Google Workspace MFA Disabled',
  [AlertCategory.GWS_MFA_ENABLED]: 'Google Workspace MFA Enabled',
  [AlertCategory.GWS_PASSWORD_RESET]: 'Google Workspace Password Reset',
  [AlertCategory.GWS_USER_CREATE]: 'Google Workspace User Create',
  [AlertCategory.GWS_USER_DELETE]: 'Google Workspace User Delete',
  [AlertCategory.GWS_LOGIN_TOKEN]: 'Google Workspace Login Using Token',
  [AlertCategory.GWS_UNAPPROVED_GEO_LOGIN]: 'Google Workspace Login From Unapproved Location',
  [AlertCategory.GWS_NO_MFA]: 'Google Workspace No MFA User',
  [AlertCategory.GWS_DEVICE]: 'Google Workspace Registered Device',
  [AlertCategory.GWS_ADMIN_ROLE_ASSIGNMENT]: 'Google Workspace Admin Role Assignment',
  [AlertCategory.GWS_GROUP_MEMBERSHIP_CHANGE]: 'Google Workspace Group Membership Change',
  [AlertCategory.GWS_ADMIN_POLICY_CHANGE]: 'Google Workspace Admin Policy Change',
};

export enum AlertMetric {
  SEVERITY = 'severity',
  EPSS = 'epss',
  CVSS = 'cvss',
  COUNT = 'count',
  ANY = 'any',
}

export enum AlertSeverity {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
  INFO = 'Info',
}

export const ALERT_METRIC_LABELS: { [key in AlertMetric]: string } = {
  [AlertMetric.SEVERITY]: 'Severity Equals',
  [AlertMetric.EPSS]: 'EPSS Greater Than',
  [AlertMetric.CVSS]: 'CVSS Greater Than',
  [AlertMetric.COUNT]: 'Count Greater Than',
  [AlertMetric.ANY]: 'Any New',
};

export const ALERT_METRIC_VALUES: { [key in AlertMetric]: string } = {
  [AlertMetric.SEVERITY]: AlertSeverity.HIGH as string,
  [AlertMetric.EPSS]: '50',
  [AlertMetric.CVSS]: '8.0',
  [AlertMetric.COUNT]: '10',
  [AlertMetric.ANY]: '',
};

export enum AlertConditionOperator {
  END = 'end',
  AND = 'and',
  OR = 'or',
}

export interface AlertCondition {
  metric: AlertMetric;
  value: string;
  operator: AlertConditionOperator;
}

export interface AlertPreference {
  id: string;
  agentId: string;
  category: AlertCategory;
  conditions?: AlertCondition[];
  condition?: AlertCondition;
  email: boolean;
  text: boolean;
}

export const ALERT_METRICS: { [key in AlertCategory]: AlertMetric[] } = {
  [AlertCategory.INTERNAL_VULNERABILITIES]: [AlertMetric.SEVERITY, AlertMetric.EPSS, AlertMetric.CVSS],
  [AlertCategory.TYPOSQUATTING]: [AlertMetric.ANY],

  [AlertCategory.DARK_WEB_BREACH]: [AlertMetric.ANY],
  [AlertCategory.DARK_WEB_ACCOUNT]: [AlertMetric.ANY],

  [AlertCategory.M365_FAILED_LOGINS]: [AlertMetric.COUNT],
  [AlertCategory.M365_AUTH_REVOKED]: [AlertMetric.ANY],
  [AlertCategory.M365_CONDITIONAL_ACCESS_VIOLATION]: [AlertMetric.ANY],
  [AlertCategory.M365_MFA_FAILED]: [AlertMetric.ANY],
  [AlertCategory.M365_SUCCESS_LOGINS]: [AlertMetric.ANY],
  [AlertCategory.M365_MFA_DISABLED]: [AlertMetric.ANY],
  [AlertCategory.M365_MFA_ENABLED]: [AlertMetric.ANY],
  [AlertCategory.M365_PASSWORD_RESET]: [AlertMetric.ANY],
  [AlertCategory.M365_USER_CREATE]: [AlertMetric.ANY],
  [AlertCategory.M365_USER_DELETE]: [AlertMetric.ANY],
  [AlertCategory.M365_LOGIN_TOKEN]: [AlertMetric.ANY],
  [AlertCategory.M365_UNAPPROVED_GEO_LOGIN]: [AlertMetric.ANY],
  [AlertCategory.M365_NO_MFA]: [AlertMetric.ANY],
  [AlertCategory.M365_DEVICE]: [AlertMetric.ANY],
  [AlertCategory.M365_ADMIN_ROLE_ASSIGNMENT]: [AlertMetric.ANY],
  [AlertCategory.M365_GROUP_MEMBERSHIP_CHANGE]: [AlertMetric.ANY],
  [AlertCategory.M365_ADMIN_POLICY_CHANGE]: [AlertMetric.ANY],

  [AlertCategory.GWS_FAILED_LOGINS]: [AlertMetric.COUNT],
  [AlertCategory.GWS_AUTH_REVOKED]: [AlertMetric.ANY],
  [AlertCategory.GWS_SUCCESS_LOGINS]: [AlertMetric.ANY],
  [AlertCategory.GWS_MFA_FAILED]: [AlertMetric.ANY],
  [AlertCategory.GWS_MFA_DISABLED]: [AlertMetric.ANY],
  [AlertCategory.GWS_MFA_ENABLED]: [AlertMetric.ANY],
  [AlertCategory.GWS_PASSWORD_RESET]: [AlertMetric.ANY],
  [AlertCategory.GWS_USER_CREATE]: [AlertMetric.ANY],
  [AlertCategory.GWS_USER_DELETE]: [AlertMetric.ANY],
  [AlertCategory.GWS_LOGIN_TOKEN]: [AlertMetric.ANY],
  [AlertCategory.GWS_UNAPPROVED_GEO_LOGIN]: [AlertMetric.ANY],
  [AlertCategory.GWS_NO_MFA]: [AlertMetric.ANY],
  [AlertCategory.GWS_DEVICE]: [AlertMetric.ANY],
  [AlertCategory.GWS_ADMIN_ROLE_ASSIGNMENT]: [AlertMetric.ANY],
  [AlertCategory.GWS_GROUP_MEMBERSHIP_CHANGE]: [AlertMetric.ANY],
  [AlertCategory.GWS_ADMIN_POLICY_CHANGE]: [AlertMetric.ANY],
};

export const ALERT_CONDITIONS: { [key in AlertCategory]: AlertCondition } = {
  [AlertCategory.INTERNAL_VULNERABILITIES]: {
    metric: AlertMetric.SEVERITY,
    value: ALERT_METRIC_VALUES[AlertMetric.SEVERITY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.TYPOSQUATTING]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },

  [AlertCategory.DARK_WEB_BREACH]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.DARK_WEB_ACCOUNT]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },

  [AlertCategory.M365_FAILED_LOGINS]: {
    metric: AlertMetric.COUNT,
    value: ALERT_METRIC_VALUES[AlertMetric.COUNT],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_AUTH_REVOKED]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_CONDITIONAL_ACCESS_VIOLATION]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_MFA_FAILED]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_SUCCESS_LOGINS]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_MFA_DISABLED]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_MFA_ENABLED]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_PASSWORD_RESET]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_USER_CREATE]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_USER_DELETE]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_LOGIN_TOKEN]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_UNAPPROVED_GEO_LOGIN]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_NO_MFA]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_DEVICE]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_ADMIN_ROLE_ASSIGNMENT]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_GROUP_MEMBERSHIP_CHANGE]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_ADMIN_POLICY_CHANGE]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },

  [AlertCategory.GWS_FAILED_LOGINS]: {
    metric: AlertMetric.COUNT,
    value: ALERT_METRIC_VALUES[AlertMetric.COUNT],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.GWS_AUTH_REVOKED]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.GWS_SUCCESS_LOGINS]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.GWS_MFA_FAILED]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.GWS_MFA_DISABLED]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.GWS_MFA_ENABLED]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.GWS_PASSWORD_RESET]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.GWS_USER_CREATE]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.GWS_USER_DELETE]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.GWS_LOGIN_TOKEN]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.GWS_UNAPPROVED_GEO_LOGIN]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.GWS_NO_MFA]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.GWS_DEVICE]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.GWS_ADMIN_ROLE_ASSIGNMENT]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.GWS_GROUP_MEMBERSHIP_CHANGE]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.GWS_ADMIN_POLICY_CHANGE]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
};

export interface AlertData {
  content: string;
  previous: string;
  filteredUsers?: DeepScanM365User[];
  filteredLogins?: DeepScanM365AuditLogSignIn[];
  filteredFindings?: SecurityFindingDTO[];
  filteredCves?: NmapVulnerabilityData[];
  filteredGwsUsers?: DeepScanGwsUser[];
  filteredGwsLogins?: DeepScanGwsActivityLogin[];
  filteredBreachData?: ApplicationBreachDataDTO[];
  hostName?: string;
}

export interface AlertLatestState {
  cvePublishedDate?: string;
  typosquattingRegisteredDate?: string;
  m365UserCreatedAt?: string;
  m365LoginAuditCreatedAt?: string;
}

export interface AlertDTO {
  id: string;
  application: ApplicationDTO;
  applicationId: string;
  agent: AgentDTO | null;
  agentId: string;
  agency: AgencyDTO | null;
  agencyId: string;
  createdAt: Date;
  updatedAt: Date;
  data: AlertData | null;
  category: AlertCategory;
  read: boolean;
}

export interface AlertsQuery {
  q?: string;
  unread?: boolean;
  alertCategory?: AlertCategory;
  offset?: number;
  limit?: number;
  creationDateDays?: number;
  demo?: boolean;
}

export interface AlertsResponse {
  message: string;
  alerts: AlertDTO[];
  alertsCount: number;
}

export interface EditAlertDTO {
  read: boolean | null;
}

export interface EditAlertPreferenceDTO {
  id: string;
  alertPreferences: AlertPreference[];
}
