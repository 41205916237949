import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import cx from 'classnames';
import { LoadingContainer } from 'components/LoadingContainer';
import { Modal } from 'components/Modal';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import dayjs from 'dayjs';
// import { AgentDTO } from 'dtos/agent';
import { ALERT_CATEGORIES, AlertCategory } from 'dtos/alert';
import { ApplicationBreachDataDTO } from 'dtos/application';
import { DeepScanM365AuditLogSignIn, DeepScanM365User, NmapVulnerabilityData } from 'dtos/deep-scan';
// import { useExternalScanFindings } from 'hooks/external-scan/useExternalScanFindings';
import { SecurityFindingSlug } from 'dtos/security-finding';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { useAlert } from 'queries/useAlerts';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { ScoreSecurityCard } from 'templates/SecurityAssessments/components/common/ScoreSecurityCard';
import { ScoreBox } from 'templates/SecurityAssessments/components/ScoreBox';
import { SecurityFindingsTable } from 'templates/SecurityAssessments/components/SecurityFindingsTable';
import {
  // NmapVulnerabilitiesDetailsModal,
  SEVERITY,
  SEVERITY_TO_SCORE_RANGE,
} from 'templates/SecurityAssessments/components/VulnerabilitiesDetailsModal/NmapVulnerabilitiesDetailsModal';
import { DeepScanGwsUserRow } from 'templates/SecurityAssessments/views/deep-scan/GoogleWsSecurity';
import {
  AUTH_METHODS,
  DeepScanM365UserRow,
  getMFAScoreRanking,
} from 'templates/SecurityAssessments/views/deep-scan/M365Security';
// import { LIMITS } from 'views/agent/views/application-details/views/monitoring/RiskAssessmentHistory';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `${theme.spacing()}px ${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(4)}px`,
    boxSizing: 'border-box',
  },

  title: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    ...TYPOGRAPHY.LARGE,
    // margin: 'auto 15px auto 0',
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(),
    marginTop: theme.spacing(),
  },

  subtitle: {
    ...TYPOGRAPHY.LARGE,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(1),
  },

  section: {
    marginBottom: theme.spacing(1),
  },

  headerRow: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  boxBorderPadding: {
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
    width: '50%',
  },

  securityCard: {
    flex: 1,
  },

  filterBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoIcon: {
    color: `${COLORS.GREY_4} !important`,
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(-0.5),
    transition: 'color 0.2s ease-in-out',
    fontSize: 16,

    '&:hover': {
      color: `${COLORS.BLUE_1} !important`,
    },
  },
  red: {
    color: COLORS.RED_1,
  },
  yellow: {
    color: COLORS.YELLOW_1,
  },
  green: {
    color: COLORS.GREEN_1,
  },
  blue: {
    color: COLORS.BLUE_1,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  actionButton: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

export const AlertDetailsView = () => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();

  const [cvesSorter, setCvesSorter] = useState<Sorter<string> | undefined>({
    key: 'epss',
    order: SortOrder.DESC,
  });

  const { data: alert, isLoading } = useAlert(id || '', {
    enabled: Boolean(id),
  });

  const handleViewAssessment = useCallback(() => {
    if (!alert) return;
    let fragment = '';

    switch (alert.category) {
      case AlertCategory.INTERNAL_VULNERABILITIES:
        fragment = `/security/latest/${ROUTES.agent.application.security.deepScan.NETWORK_SECURITY}`;
        break;

      case AlertCategory.TYPOSQUATTING:
        fragment = `/security/latest/${ROUTES.agent.application.security.deepScan.DARK_WEB_PRESENCE}/finding/${SecurityFindingSlug.TYPO_SQUATTING}`;
        break;

      case AlertCategory.DARK_WEB_BREACH:
      case AlertCategory.DARK_WEB_ACCOUNT:
        fragment = `/security/latest/${ROUTES.agent.application.security.deepScan.DARK_WEB_PRESENCE}`;
        break;

      case AlertCategory.M365_FAILED_LOGINS:
      case AlertCategory.M365_AUTH_REVOKED:
      case AlertCategory.M365_CONDITIONAL_ACCESS_VIOLATION:
      case AlertCategory.M365_MFA_FAILED:
      case AlertCategory.M365_SUCCESS_LOGINS:
      case AlertCategory.M365_MFA_DISABLED:
      case AlertCategory.M365_MFA_ENABLED:
      case AlertCategory.M365_PASSWORD_RESET:
      case AlertCategory.M365_USER_CREATE:
      case AlertCategory.M365_USER_DELETE:
      case AlertCategory.M365_LOGIN_TOKEN:
      case AlertCategory.M365_UNAPPROVED_GEO_LOGIN:
      case AlertCategory.M365_NO_MFA:
      case AlertCategory.M365_DEVICE:
      case AlertCategory.M365_ADMIN_ROLE_ASSIGNMENT:
      case AlertCategory.M365_GROUP_MEMBERSHIP_CHANGE:
      case AlertCategory.M365_ADMIN_POLICY_CHANGE:
        fragment = `/security/latest/${ROUTES.agent.application.security.deepScan.M365_SECURITY}`;
        break;

      case AlertCategory.GWS_FAILED_LOGINS:
      case AlertCategory.GWS_AUTH_REVOKED:
      case AlertCategory.GWS_SUCCESS_LOGINS:
      case AlertCategory.GWS_MFA_FAILED:
      case AlertCategory.GWS_MFA_DISABLED:
      case AlertCategory.GWS_MFA_ENABLED:
      case AlertCategory.GWS_PASSWORD_RESET:
      case AlertCategory.GWS_USER_CREATE:
      case AlertCategory.GWS_USER_DELETE:
      case AlertCategory.GWS_LOGIN_TOKEN:
      case AlertCategory.GWS_UNAPPROVED_GEO_LOGIN:
      case AlertCategory.GWS_NO_MFA:
      case AlertCategory.GWS_DEVICE:
      case AlertCategory.GWS_ADMIN_ROLE_ASSIGNMENT:
      case AlertCategory.GWS_GROUP_MEMBERSHIP_CHANGE:
      case AlertCategory.GWS_ADMIN_POLICY_CHANGE:
        fragment = `/security/latest/${ROUTES.agent.application.security.deepScan.GWS_SECURITY}`;
        break;
    }

    return navigate(insertIdToRoute(ROUTES.agent.application.ROOT, alert.applicationId) + fragment);
  }, [navigate, alert]);

  const total = useMemo(() => {
    if (!alert) return 0;

    switch (alert.category) {
      case AlertCategory.INTERNAL_VULNERABILITIES:
        return (alert.data?.filteredCves || []).length;

      case AlertCategory.TYPOSQUATTING:
        return (alert.data?.filteredFindings || []).length;

      case AlertCategory.DARK_WEB_BREACH:
      case AlertCategory.DARK_WEB_ACCOUNT:
        return (alert.data?.filteredBreachData || []).length;

      case AlertCategory.M365_FAILED_LOGINS:
        return (alert.data?.filteredLogins || []).length;

      case AlertCategory.M365_NO_MFA:
        return (alert.data?.filteredUsers || []).length;

      case AlertCategory.GWS_FAILED_LOGINS:
        return (alert.data?.filteredGwsLogins || []).length;

      case AlertCategory.GWS_NO_MFA:
        return (alert.data?.filteredGwsUsers || []).length;

      default:
        return 0;
    }
  }, [alert]);

  // M365_FAILED_LOGINS
  const loginColumns: Array<Column<DeepScanM365AuditLogSignIn, keyof DeepScanM365AuditLogSignIn>> = useMemo(() => {
    if (!alert) return [];

    return [
      {
        title: 'Date Time',
        width: `10%`,
        render: (login) => dayjs(login.createdDateTime).format('M/D/YYYY HH:mm:ss'),
      },
      {
        title: 'Location',
        width: `10%`,
        render: (login) => `${login.location.city}, ${login.location.countryOrRegion}`,
      },
      {
        title: 'User',
        width: `10%`,
        render: (login) => login.userPrincipalName,
      },
      {
        title: 'Device',
        width: `20%`,
        render: (login) => `${login.deviceDetail.browser}, ${login.deviceDetail.operatingSystem}`,
      },
      {
        title: 'Failure Reason',
        width: `calc(X)`,
        render: (login) => login.status.failureReason,
      },
    ];
  }, [alert]);

  // M365_NO_MFA
  const userColumns: Array<Column<DeepScanM365UserRow, keyof DeepScanM365UserRow>> = useMemo(() => {
    if (!alert) return [];

    const accountTypeColumn: Column<DeepScanM365UserRow, keyof DeepScanM365UserRow> = {
      title: 'Account Type',
      sortKey: 'accountType',
      render: (row: DeepScanM365UserRow) => row.accountType,
    };

    const signInStatusColumn: Column<DeepScanM365UserRow, keyof DeepScanM365UserRow> = {
      title: 'Sign-in Status',
      sortKey: 'accountEnabled',
      render: (row: DeepScanM365UserRow) => (
        <ScoreBox
          ranking={getMFAScoreRanking(
            row.accountEnabled !== undefined ? (row.accountEnabled ? 'Enabled' : 'Not enabled') : 'n/a',
          )}
          label={row.accountEnabled !== undefined ? (row.accountEnabled ? 'Enabled' : 'Disabled') : 'n/a'}
        />
      ),
    };

    const lastLoginColumn: Column<DeepScanM365UserRow, keyof DeepScanM365UserRow> = {
      title: 'Last Login',
      sortKey: 'lastLogin',
      render: (row: DeepScanM365UserRow) => (row.lastLogin ? dayjs(row.lastLogin).format('M/D/YYYY h:mm A') : 'N/A'),
    };

    return [
      {
        title: 'Name',
        sortKey: 'displayName',
        render: (row) => row.displayName,
      },
      {
        title: 'Email',
        sortKey: 'mail',
        render: (row) => row.mail,
      },
      accountTypeColumn,
      signInStatusColumn,
      {
        title: 'Admin Role',
        sortKey: 'adminRole',
        render: (row) => row.adminRole,
      },
      {
        title: 'MFA Status',
        sortKey: 'mfaStatus',
        render: (row) => {
          let mfaStatus = 'N/A';
          if (row.mfaStatus != null) {
            mfaStatus = row.mfaStatus > 0 ? 'Enabled' : 'Not enabled';
          }

          return <ScoreBox ranking={getMFAScoreRanking(mfaStatus)} label={mfaStatus} />;
        },
      },
      {
        title: 'Authentication Methods',
        sortKey: 'authenticationMethods',
        render: (row) => row.authenticationMethods,
      },
      lastLoginColumn,
    ];
  }, [alert]);

  const usersData: DeepScanM365UserRow[] = useMemo(() => {
    return (alert?.data?.filteredUsers || []).map((row: DeepScanM365User) => {
      let mfaStatus = null;
      if (!row.mailboxSettings?.userPurpose || row.mailboxSettings?.userPurpose != 'shared') {
        mfaStatus = (row.authenticationMethods || []).length > 1 ? 1 : 0;
      }

      const authenticationMethods = (row.authenticationMethods || [])
        .map((m) => AUTH_METHODS[m['@odata.type']] || 'N/A')
        .join(', ');

      const lastLogin = row.signInActivity?.lastNonInteractiveSignInDateTime || row.signInActivity?.lastSignInDateTime;

      return {
        id: row.id,
        accountType: row.mailboxSettings?.userPurpose || 'n/a',
        lastLogin,
        accountEnabled: row.accountEnabled,
        adminRole: (row.memberOf?.value || [])
          .map((r) => (r.displayName.indexOf('Admin') > -1 ? r.displayName.split(' ')[0] : ''))
          .filter((dn) => dn)
          .join(', '),
        displayName: row.displayName,
        mail: row.mail,
        mfaStatus,
        authenticationMethods,
      };
    });
  }, [alert]);

  // GWS_NO_MFA
  const gwsUserColumns = useMemo((): Column<DeepScanGwsUserRow, keyof DeepScanGwsUserRow>[] => {
    return [
      {
        title: 'Name',
        sortKey: 'fullName',
        render: (row) => row.fullName,
      },
      {
        title: 'Email',
        sortKey: 'primaryEmail',
        render: (row) => row.primaryEmail,
      },
      {
        title: 'Sign-in Status',
        sortKey: 'status',
        render: (row) => (
          <ScoreBox
            ranking={getMFAScoreRanking(
              row.status == 'Suspended' || row.status == 'Archived' ? 'Not Enabled' : 'Enabled',
            )}
            label={row.status}
          />
        ),
      },
      {
        title: 'MFA Status',
        sortKey: 'mfaStatus',
        render: (row) => {
          const mfaStatus = row.mfaStatus > 1 ? 'Enabled' : 'Not enabled';
          return <ScoreBox ranking={getMFAScoreRanking(mfaStatus)} label={mfaStatus} />;
        },
      },
      {
        title: 'Last Login',
        sortKey: 'lastLogin',
        render: (row) => (row.lastLogin ? dayjs(row.lastLogin).format('M/D/YYYY h:mm A') : 'N/A'),
      },
    ];
  }, []);

  const gwsUsersData: DeepScanGwsUserRow[] = useMemo(() => {
    return (alert?.data?.filteredGwsUsers || []).map((row) => {
      return {
        id: row.id,
        fullName: row.name.fullName,
        primaryEmail: row.primaryEmail,
        status: row.suspended ? 'Suspended' : row.archived ? 'Archived' : 'Active',
        mfaStatus: row.isEnrolledIn2Sv ? 2 : 1,
        lastLogin: row.lastLoginTime,
      };
    });
  }, [alert]);

  // INTERNAL_VULNERABILITIES
  const cvesColumns = useMemo((): Column<NmapVulnerabilityData, string>[] => {
    if (!alert) return [];

    return [
      {
        width: 180,
        title: '# / CVE',
        sortKey: 'id',
        render: (row) =>
          row.score && row.id !== 'INFO' ? (
            <a href={`https://nvd.nist.gov/vuln/detail/${row.id}`} target='_blank' rel='noreferrer'>
              {row.id}
            </a>
          ) : (
            'INFO'
          ),
      },
      {
        title: 'Title',
        sortKey: 'description',
        render: (row) =>
          row.title || `${row.description?.slice(0, 50)}${(row.description?.length ?? 0) > 50 ? '...' : ''}`,
      },
      {
        title: 'Source',
        sortKey: 'foundIn.product',
        render: (row) =>
          row.score ? (
            <span>
              {row.foundIn?.product}
              <br />
              {row.foundIn?.version}
            </span>
          ) : row.foundIn?.port ? (
            `Port ${row.foundIn?.port}`
          ) : (
            ''
          ),
      },
      {
        title: 'Published Date',
        sortKey: 'publishedDate',
        render: (row) => (row.publishedDate ? moment(row.publishedDate).format('DD MMM YYYY') : null),
      },
      {
        title: (
          <span>
            EPSS
            <Modal openButton={<InfoRoundedIcon className={classes.infoIcon} />} title='EPSS'>
              <p>
                The EPSS (Exploit Prediction Scoring System) score represents the probability of exploitation in the
                wild during the next 30 days in the range from 0 to 1. For example, a CVE with EPSS of 0.96 has 96%
                chance to be exploited in the following 30 days.
              </p>
            </Modal>
          </span>
        ),
        sortKey: 'epss',
        render: (row) => row.epss && `${(row.epss * 100).toFixed(2)}%`,
      },
      {
        title: 'CVSS',
        sortKey: 'score',
        render: (row) => row.score,
      },
      {
        width: 80,
        title: 'Severity',
        render: (row) => {
          let color = classes.green;
          let label = row.score || 'Info';
          if (row.score && row.score > SEVERITY_TO_SCORE_RANGE[SEVERITY.HIGH][0]) {
            color = classes.red;
            label = 'High';
          } else if (row.score && row.score > SEVERITY_TO_SCORE_RANGE[SEVERITY.MEDIUM][0]) {
            color = classes.yellow;
            label = 'Medium';
          } else if (row.score && row.score > SEVERITY_TO_SCORE_RANGE[SEVERITY.LOW][0]) {
            color = classes.blue;
            label = 'Low';
          }

          return (
            <div className={classes.row}>
              <b className={cx(color)}>{label}</b>
            </div>
          );
        },
      },
      {
        title: 'Hostname',
        // sortKey: 'assets',
        render: () => alert.data?.hostName,
      },
    ];
  }, [classes, alert]);

  const sortedInternalVulnerabilities = useMemo(() => {
    const filteredData = alert?.data?.filteredCves || [];

    if (cvesSorter && filteredData) {
      const notNullCves = filteredData.filter((d) => d.score);
      const nullCves = filteredData.filter((d) => !d.score);

      const notNullData = sortBy(notNullCves, cvesSorter.key);
      const nullData = sortBy(nullCves, cvesSorter.key);

      if (cvesSorter.order === SortOrder.DESC) {
        return ([] as NmapVulnerabilityData[]).concat(notNullData.reverse(), nullData.reverse());
      }

      return ([] as NmapVulnerabilityData[]).concat(notNullData, nullData);
    }

    return filteredData;
  }, [alert, cvesSorter]);

  // DARK WEB
  const breachDataColumns: Column<ApplicationBreachDataDTO, keyof ApplicationBreachDataDTO>[] = [
    {
      title: 'Breach Name',
      render: (record) => (record?.lastBreach ? `${record?.breachName} (${record?.lastBreach})` : record?.breachName),
    },
    {
      title: 'Email',
      render: (record) => record?.email,
    },
    {
      title: 'Password',
      width: '10%',
      render: (record) => record?.password,
    },
    {
      title: 'IP Address',
      render: (record) => record?.ipAddress,
    },
    {
      title: 'Phone',
      render: (record) => record?.phone,
    },
    {
      title: 'Name',
      render: (record) => record?.name,
    },
    {
      title: 'Address',
      width: '10%',
      render: (record) => record?.address,
    },
  ];

  const handleViewAllAlerts = useCallback(
    () => navigate(`${ROUTES.agent.ROOT}/${ROUTES.agent.alerts.ROOT}/${ROUTES.agent.alerts.HISTORY}`),
    [navigate],
  );

  if (isLoading || !alert) {
    return <LoadingContainer />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <div className={classes.filterBar}>
          <div className={classes.title}>{ALERT_CATEGORIES[alert.category]}</div>
          <div>
            <Button
              variant='outlined'
              color='default'
              startIcon={<ChevronLeftRoundedIcon />}
              onClick={() => handleViewAllAlerts()}
              className={classes.actionButton}
            >
              All Alerts
            </Button>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => handleViewAssessment()}
              className={classes.actionButton}
            >
              View Assessment
            </Button>
          </div>
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.headerRow}>
          <ScoreSecurityCard
            className={classes.securityCard}
            title={`New ${ALERT_CATEGORIES[alert.category]}`}
            skipGrading={(total || 0).toLocaleString()}
            score={total > 0 ? 50 : 100}
          />
        </div>
      </div>

      <div className={classes.section}>
        {alert.category === AlertCategory.M365_FAILED_LOGINS && (
          <Table<DeepScanM365AuditLogSignIn, keyof DeepScanM365AuditLogSignIn>
            data={alert.data?.filteredLogins}
            columns={loginColumns}
            loading={isLoading}
            rowKey={(alert) => alert.id}
          />
        )}

        {alert.category === AlertCategory.M365_NO_MFA && (
          <Table<DeepScanM365UserRow, keyof DeepScanM365UserRow>
            data={usersData}
            columns={userColumns}
            loading={isLoading}
            rowKey={(alert) => alert.id}
          />
        )}

        {alert.category === AlertCategory.GWS_NO_MFA && (
          <Table<DeepScanGwsUserRow, keyof DeepScanGwsUserRow>
            data={gwsUsersData}
            columns={gwsUserColumns}
            loading={isLoading}
            rowKey={(alert) => alert.id}
          />
        )}

        {alert.category === AlertCategory.TYPOSQUATTING && (
          <SecurityFindingsTable
            data={alert.data?.filteredFindings || []}
            isLoading={isLoading}
            // onSelect={(ids) => setAction({ ...action, findingIds: ids })}
            // selectedIds={action?.findingIds || []}
          />
        )}

        {(alert.category === AlertCategory.DARK_WEB_BREACH || alert.category === AlertCategory.DARK_WEB_ACCOUNT) && (
          <Table<ApplicationBreachDataDTO, keyof ApplicationBreachDataDTO>
            data={alert.data?.filteredBreachData || []}
            columns={breachDataColumns}
            loading={isLoading}
            rowKey={(alert) => alert.id}
          />
        )}

        {alert.category === AlertCategory.INTERNAL_VULNERABILITIES && (
          <Table<NmapVulnerabilityData, string>
            sorter={cvesSorter}
            columns={cvesColumns}
            onChange={(pagination, sorting) => {
              setCvesSorter(sorting);
            }}
            rowContentCentered
            // className={classes.table}
            rowKey={(row) => row.id}
            data={sortedInternalVulnerabilities}
            loading={isLoading}
          />
        )}
      </div>
    </div>
  );
};
